<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-site-manager") }}</h1>
      <div>
        <!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->
<!--        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>-->
      </div>
    </div>
    <div class="contents liveRegister">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="title">{{ $t("event-data-site-name") }}</option>
            <option value="address">{{ $t("event-data-site-addr") }}</option>
            <option value="viewId">{{ $t("event-data-site-num") }}</option>
            <option value="departmentName">{{ $t("event-data-site-dept") }}</option>
            <option value="managerName">{{ $t("event-data-site-manager") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("event-data-site-status") }}</p>
          <label
          ><input
              type="radio"
              name="status"
              v-model="statuses"
              :value="null"
              checked
          />{{ $t("event-data-status-all") }}</label
          >
          <label
          ><input
              type="radio"
              name="status"
              v-model="statuses"
              value="Ready"
          />{{ $t("event-data-status-ready") }}</label
          >
          <label
          ><input
              type="radio"
              name="status"
              v-model="statuses"
              value="Open"
          />{{ $t("event-data-status-on-going") }}</label
          >
          <label
          ><input
              type="radio"
              name="status"
              v-model="statuses"
              value="Close"
          />{{ $t("event-data-status-close") }}</label
          >
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 15px;">
            <button class="point medium" @click="search" style="width: 120px; margin-left: 0px;">
              {{ $t("btn-searching") }}
            </button>
            <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
          </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }} <span class="blue">{{ total }}</span
        >{{ $t("data-case-ea") }}
          <router-link to="register"
          ><button class="samll right">{{ $t("btn-site-register") }}</button></router-link
          >
        </h2>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
          </div> -->
        <table style="table-layout: fixed">
          <tr>
            <th>No</th>
            <th>{{ $t("event-data-site-num") }}</th>
            <th>{{ $t("event-data-site-status") }}</th>
            <th>{{ $t("event-data-site-dist") }}</th>
            <th>{{ $t("event-data-site-name") }}</th>
            <th>{{ $t("event-data-site-addr") }}</th>
            <th>{{ $t("event-data-site-dept") }}</th>
            <th>{{ $t("event-data-site-manager") }}</th>
            <th>{{ $t("event-data-site-read-restrict") }}</th>
<!--            <th>{{ $t("378") }}</th>-->
            <th>{{ $t("event-data-site-regdate") }}</th>
          </tr>
          <colgroup>
            <col style="width: 5%;"/>
            <col style="width: 8%;"/>
            <col style="width: 7%;"/>
            <col style="width: 7%;"/>
            <col style="width: 12%;"/>
            <col style="width: 25%;"/>
            <col style="width: 11%;"/>
            <col style="width: 10%;"/>
            <col style="width: 5%;"/>
            <col style="width: 10%;"/>
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="10" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(item, i) in eventList" :key="i">
            <td>{{ total - (pageNumber * pageSize + i) }}</td>
            <td>
              {{ item.viewId != null ? item.viewId : item.eventId }}
            </td>
            <td>
              {{
                item.status == "Ready"
                    ? $t("event-data-status-ready")
                    : item.status == "Open"
                    ? $t("event-data-status-on-going")
                    : item.status == "Close"
                        ? $t("event-data-status-close")
                        : "-"
              }}
            </td>
            <td>{{ item.address.split(" ")[0] }}</td>
            <td>
              <router-link
                  to=""
                  @click.native="handleDetail(item.eventId)"
                  class="link"
              >{{ item.title }}</router-link
              >
            </td>
            <td>{{ item.address + " " + item.addressDetail }}</td>
            <td>
              {{ item.managerDepartmentTitle}}
            </td>
            <td>{{ item.managerName }}</td>
            <td>{{ item.isAuth ? "제한" : "제한없음" }}</td>
<!--            <td>{{ item.isHide ? "숨김" : "숨기지 않음" }}</td>-->
            <td>
              {{ moment(item.registerDate).format("YYYY.MM.DD HH:mm:ss") }}
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :currentPage = "pageNumber + 1"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchEventList } from "@/api/event";
export default {
  components: { Datepicker },
  name: "EventList",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      eventList: [],
      pageNumber: 0,
      pageSize: 10,
      total: 0,
      keywordType: "all",
      keyword: null,
      statuses: null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 4, sub: 0 });
    this.preset();
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  methods: {
    preset(){
      var localStorageData = localStorage.getItem("event_searchData");
      if(localStorageData != undefined) {
        var searchData = JSON.parse(localStorageData);
        this.keyword = searchData.keyword;
        this.keywordType = searchData.keywordType;
        this.statuses = searchData.statuses;
        this.pageNumber = searchData.pageNumber;
        this.startYmd =  searchData["dateRange.from"];
        this.endYmd =  searchData["dateRange.to"];
      }
    },
    persist(params){
      localStorage.setItem("event_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    },

    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleDetail(id) {
      this.$router.push({ name: "eventRegister", query: { eventId: id } });
    },
    reset() {
      this.pageNumber = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
      this.statuses = null;
    },
    search(){
      if(this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }

      this.pageNumber = 0;
      this.getEventList();
    },
    getEventList() {
      var params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        keywordType: this.keywordType,
        keyword: this.keyword,
        statuses: this.statuses,
      };

      if(this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }

      this.persist(params);
      fetchEventList(params).then((res) => {
        this.total = res.data.data.total;
        this.eventList = res.data.data.content;
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val - 1;
      this.getEventList();
    },
    handleRefresh() {
      this.reset();//변수 초기화
      this.callFunctionsWhenMounted();
    },
    callFunctionsWhenMounted(){
      this.getEventList();
    }
  },
};
</script>
